import React from 'react';
import {Box, Button, Grid, Typography} from "@mui/material";
const clients = [
    'IHEARTRADIO', 'GM', 'FORD', 'UNITED HEALTHCARE', 'OPTUM',
    'PETSMART'
];

const Technologies = () => {
    return (

            <Box className="tech-stack">
                <Typography variant="h6" className="tech-title">
                    Companies our developers have worked with
                </Typography>
                <Grid container spacing={2}>
                    {clients.map((tech, index) => (
                        <Grid item xs={12} sm={2} key={index}>
                            <Button
                                variant="outlined"
                                className="tech-button"
                            >
                                {tech}
                            </Button>
                        </Grid>
                    ))}
                </Grid>
            </Box>

    );
}
export default Technologies;