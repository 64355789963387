import React, { useState } from 'react';
import { Box, Button, Modal, Typography, Container, Stepper, Step, StepLabel, IconButton, Grid } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import BudgetCalculator from "../BudgetCalculator/BudgetCalculator";
import Contact from "../Contact/Contact";
import './_Modal.scss';

const steps = ['Introduction', 'Project Estimator', 'Contact Us'];

const ProjectModal = ({ open, handleClose, initialStep = 0 }) => { // Add initialStep prop
    const [activeStep, setActiveStep] = useState(initialStep); // Set activeStep from prop

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        if (activeStep === 2) {
            setActiveStep(0); // Go back to intro on Contact Form page
        } else {
            setActiveStep((prevActiveStep) => prevActiveStep - 1);
        }
    };

    const handleEmailUs = () => {
        setActiveStep(2); // Jump directly to the contact form
    };

    const handleModalClose = () => {
        setActiveStep(0); // Reset to the first step when closing the modal
        handleClose(); // Close the modal
    };

    return (
        <Modal open={open} onClose={handleModalClose} className="project-modal">
            <Box className="modal-box" sx={{ backgroundColor: '#121212', color: '#fff' }}>
                {/* Close Button */}
                <IconButton
                    aria-label="close"
                    onClick={handleModalClose}
                    sx={{ position: 'absolute', top: 16, right: 16, color: '#fff' }}
                >
                    <CloseIcon />
                </IconButton>

                {/* Stepper */}
                <Stepper activeStep={activeStep} alternativeLabel className="stepper">
                    {steps.map((label, index) => (
                        <Step key={label}>
                            <StepLabel
                                StepIconProps={{
                                    sx: {
                                        '&.Mui-active': {
                                            color: '#EF002F', // Red color for active step
                                        },
                                    },
                                }}
                            >
                                {label}
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>

                <Box className="modal-content">
                    {activeStep === 0 && (
                        <Container className="intro-container">
                            <Typography variant="h5" gutterBottom>
                                Start Your Project
                            </Typography>
                            <Typography variant="body1" gutterBottom>
                                We’re excited to work with you on your project! You can either:
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                1. Contact us directly to discuss your project in more detail.
                            </Typography>
                            <Typography variant="body2" gutterBottom>
                                2. Use our Project Estimator to get an estimate of your project's cost.
                            </Typography>

                            <Box mt={3} display="flex" gap={2}>
                                <Button variant="contained" color="primary" onClick={handleEmailUs} className="modal-button">
                                    Email Us
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{ color: '#EF002F', borderColor: '#EF002F' }}
                                    onClick={handleNext}
                                    className="modal-button"
                                >
                                    Start Estimating
                                </Button>
                            </Box>
                        </Container>
                    )}

                    {activeStep === 1 && (
                        <Box>
                            <BudgetCalculator />
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Button onClick={handleBack} className="modal-button">
                                    Previous
                                </Button>
                                <Button
                                    variant="outlined"
                                    sx={{ color: '#EF002F', borderColor: '#EF002F' }}
                                    onClick={handleNext}
                                    className="modal-button"
                                >
                                    Submit Your Quote
                                </Button>
                            </Grid>
                        </Box>
                    )}

                    {activeStep === 2 && (
                        <Box>
                            <Contact />
                        </Box>
                    )}
                </Box>
            </Box>
        </Modal>
    );
};

export default ProjectModal;
