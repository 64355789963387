import React, { useState } from 'react';
import Header from "../../components/Header/Header";
import About from "../../components/About/About";
import Hero from "../../components/Hero/Hero";
import Portfolio from "../../components/Portfolio/Portfolio";
import Projects from "../../components/Projects/Projects";
import Contact from "../../components/Contact/Contact";
import Footer from "../../components/Footer/Footer";
import CallToAction from "../../components/CallToAction/CallToAction";
import BudgetCalculator from "../../components/BudgetCalculator/BudgetCalculator";
import ProjectModal from "../../components/ProjectModal/ProjectModal"; // Use ProjectModal instead of direct modal
import FeaturesSection from "../../components/FeaturesSection/FeaturesSection";
import { Box } from "@mui/material";

const Home = () => {
    const [contactOpen, setContactOpen] = useState(false); // State to control the modal open/close
    const [initialStep, setInitialStep] = useState(0); // State to control which step to start on

    const handleContactOpen = () => {
        setInitialStep(2); // Set the step to 3 (Contact Form)
        setContactOpen(true); // Open the modal
    };

    const handleModalClose = () => {
        setContactOpen(false); // Close the modal
        setInitialStep(0); // Reset to the first step when modal is closed
    };

    return (
        <>
            <Header handleContactOpen={handleContactOpen} /> {/* Pass the contact open handler to Header */}

            {/* Project Modal */}
            <ProjectModal
                open={contactOpen}
                handleClose={handleModalClose}
                initialStep={initialStep} // Pass the initial step to start on
            />

            <Hero />
            <FeaturesSection />
            <About />
            <Portfolio />
            <Projects />
            <CallToAction />
            <Footer />
        </>
    );
};

export default Home;
