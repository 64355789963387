import React, { useState } from 'react';
import { Container, Typography, Slider, Select, MenuItem, Checkbox, FormControlLabel, Box, TextField, Grid, Button } from '@mui/material';
import './_BudgetCalculator.scss';

const BudgetCalculator = () => {
    const [service, setService] = useState('website');
    const [price, setPrice] = useState(500);
    const [features, setFeatures] = useState({
        pages: false,
        cms: false,
        products: false,
        seo: false,
        design: false,
        social: false,
        security: false,
        forms: false,
        animations: false,
        emailMarketing: false,
        cartCustomization: false,
    });

    const handleServiceChange = (event) => {
        const selectedService = event.target.value;
        setService(selectedService);

        // Reset features when changing service
        setFeatures({
            pages: false,
            cms: false,
            products: false,
            seo: false,
            design: false,
            social: false,
            security: false,
            forms: false,
            animations: false,
            emailMarketing: false,
            cartCustomization: false,
        });

        // Set the initial price based on service selection
        if (selectedService === 'website') {
            setPrice(500);
        } else if (selectedService === 'store') {
            setPrice(2000); // Boosted base price for Online Store
        }
    };

    const handlePriceChange = (event, newValue) => {
        setPrice(newValue);
    };

    const handleFeatureChange = (event) => {
        const { name, checked } = event.target;

        // Update the selected features
        setFeatures((prev) => ({ ...prev, [name]: checked }));

        // Adjust the price based on feature selection
        let additionalPrice = 0;
        switch (name) {
            case 'pages':
                additionalPrice = 500;
                break;
            case 'cms':
                additionalPrice = 1000;
                break;
            case 'products':
                additionalPrice = 250;
                break;
            case 'seo':
                additionalPrice = 500;
                break;
            case 'design':
                additionalPrice = 700;
                break;
            case 'social':
                additionalPrice = 200;
                break;
            case 'security':
                additionalPrice = 150;
                break;
            case 'forms':
                additionalPrice = 600;
                break;
            case 'animations':
                additionalPrice = 400;
                break;
            case 'emailMarketing':
                additionalPrice = 300;
                break;
            case 'cartCustomization':
                additionalPrice = 400;
                break;
            default:
                break;
        }

        // Increase or decrease the price based on checkbox state
        setPrice((prevPrice) => checked ? prevPrice + additionalPrice : prevPrice - additionalPrice);
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Combine the budget form data
        const budgetData = {
            service,
            price,
            features: Object.keys(features).filter(key => features[key]),
        };

        // Convert the budgetData into URLSearchParams format
        const formData = new URLSearchParams();
        formData.append('service', budgetData.service);
        formData.append('price', budgetData.price);
        budgetData.features.forEach((feature, index) => {
            formData.append(`features[${index}]`, feature);
        });

        // Make a POST request to budget.php
        fetch('budget.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: formData.toString(),
        })
            .then(response => {
                if (response.ok) {
                    console.log('Budget data saved successfully');
                    // Optionally redirect to the contact form
                } else {
                    console.error('Budget form submission failed');
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    return (
        <Container className="budget-calculator-container">
            <form onSubmit={handleSubmit}>
                <Box className="budget-calculator-box">
                    <Typography variant="h5" gutterBottom>
                        Budget Calculator
                    </Typography>

                    {/* Service Selection */}
                    <Select
                        value={service}
                        onChange={handleServiceChange}
                        fullWidth
                        variant="outlined"
                        className="service-select"
                    >
                        <MenuItem value="website">Website</MenuItem>
                        <MenuItem value="store">Online Store</MenuItem>
                        <MenuItem value="web-app" disabled>Web App (Coming Soon)</MenuItem>
                        <MenuItem value="mobile-app" disabled>Mobile App (Coming Soon)</MenuItem>
                    </Select>

                    {/* Price Slider */}
                    <Box display="flex" alignItems="center" mt={3}>
                        <Slider
                            value={price}
                            min={service === 'website' ? 500 : 2000}
                            max={10000}
                            step={50}
                            onChange={handlePriceChange}
                            className="price-slider"
                            sx={{
                                color: '#e63946', // Slider color
                            }}
                        />
                        <TextField
                            value={`$${price}`}
                            onChange={(e) => setPrice(Number(e.target.value.replace(/\D/g, '')))}
                            variant="outlined"
                            className="price-input"
                            InputProps={{
                                inputProps: { min: service === 'website' ? 500 : 2000, max: 10000 }
                            }}
                        />
                    </Box>

                    {/* Included Features */}
                    <Box mt={3} mb={3}>
                        <Typography variant="h6">Included Features</Typography>
                        <Grid container spacing={2} className="included-features">
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={<Checkbox checked disabled />}
                                    label="Mobile Friendly"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked disabled />}
                                    label="Basic SEO"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={<Checkbox checked disabled />}
                                    label="1-5 Pages"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked disabled />}
                                    label="Contact Form"
                                />
                                {service === 'store' && (
                                    <>
                                        <FormControlLabel
                                            control={<Checkbox checked disabled />}
                                            label="Payment Gateway Integration"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked disabled />}
                                            label="Product Variations"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked disabled />}
                                            label="Shipping & Tax Calculators"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked disabled />}
                                            label="Inventory System"
                                        />
                                    </>
                                )}
                            </Grid>
                        </Grid>
                    </Box>

                    {/* Feature Selection */}
                    <Box mt={3}>
                        <Typography variant="h6">Additional Features</Typography>
                        <Grid container spacing={2}>
                            {service === 'website' && (
                                <>
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox checked={features.pages} onChange={handleFeatureChange} name="pages" />}
                                            label="6-12 Pages (+ $500)"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={features.cms} onChange={handleFeatureChange} name="cms" />}
                                            label="Content Management System (+ $1,000)"
                                        />
                                    </Grid>
                                </>
                            )}

                            {service === 'store' && (
                                <>
                                    <Grid item xs={6}>
                                        <FormControlLabel
                                            control={<Checkbox checked={features.products} onChange={handleFeatureChange} name="products" />}
                                            label="Every 20 Products (+ $250)"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={features.pages} onChange={handleFeatureChange} name="pages" />}
                                            label="6-12 Pages (+ $500)"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={features.cartCustomization} onChange={handleFeatureChange} name="cartCustomization" />}
                                            label="Shopping Cart Customization (+ $400)"
                                        />
                                    </Grid>
                                </>
                            )}

                            {/* Shared Add-Ons */}
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={<Checkbox checked={features.seo} onChange={handleFeatureChange} name="seo" />}
                                    label="Advanced SEO & Analytics (+ $500)"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={features.design} onChange={handleFeatureChange} name="design" />}
                                    label="Custom Design & Branding (+ $700)"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={features.social} onChange={handleFeatureChange} name="social" />}
                                    label="Social Media Integration (+ $200)"
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <FormControlLabel
                                    control={<Checkbox checked={features.security} onChange={handleFeatureChange} name="security" />}
                                    label="Security Package (+ $150)"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={features.forms} onChange={handleFeatureChange} name="forms" />}
                                    label="Custom Forms (+ $600)"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={features.animations} onChange={handleFeatureChange} name="animations" />}
                                    label="Web Animations (+ $400)"
                                />
                                <FormControlLabel
                                    control={<Checkbox checked={features.emailMarketing} onChange={handleFeatureChange} name="emailMarketing" />}
                                    label="Email Marketing Integration (+ $300)"
                                />
                            </Grid>
                        </Grid>
                    </Box>


                </Box>
            </form>
        </Container>
    );
};

export default BudgetCalculator;
