import React, { useState } from 'react';
import {
    AppBar,
    Toolbar,
    Button,
    Typography,
    Container,
    IconButton,
    Drawer,
    List,
    ListItem,
    ListItemText, useMediaQuery
} from '@mui/material';
import { Link } from 'react-scroll';
import { Menu as MenuIcon } from '@mui/icons-material';
import './_Header.scss';
import { useTheme } from '@mui/material/styles';
const Header = ({ handleContactOpen }) => {
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('md')); // For screen sizes below medium (mobile)

    const [mobileOpen, setMobileOpen] = useState(false);

    const handleDrawerToggle = () => {
        setMobileOpen(!mobileOpen);
    };

    const navLinks = (
        <>
            <Button color="inherit">
                <Link to="home" smooth={true} duration={500}>
                    Home
                </Link>
            </Button>
            <Button color="inherit">
                <Link to="about" smooth={true} duration={500}>
                    About
                </Link>
            </Button>
            <Button color="inherit">
                <Link to="portfolio" smooth={true} duration={500}>
                    Portfolio
                </Link>
            </Button>
            <Button color="inherit">
                <Link to="projects" smooth={true} duration={500}>
                    Projects
                </Link>
            </Button>
            <Button color="inherit">
                <Link to="technology" smooth={true} duration={500}>
                    Technology
                </Link>
            </Button>
            <Button onClick={handleContactOpen} className="button-red-outline">
                Contact
            </Button>
        </>
    );

    return (
        <AppBar position="sticky" className="header" sx={{ backgroundColor: '#121212' }}>
            <Container>
                <Toolbar>
                    <Typography variant="h4" className="logo">
                        <span className="red-text">E</span>
                    </Typography>

                    {isMobile ? (
                        // Show hamburger menu on mobile
                        <>
                            <IconButton
                                edge="end"
                                color="inherit"
                                aria-label="menu"
                                onClick={handleDrawerToggle}
                                sx={{ ml: 'auto' }}
                            >
                                <MenuIcon />
                            </IconButton>
                            <Drawer
                                anchor="right"
                                open={mobileOpen}
                                onClose={handleDrawerToggle}
                            >
                                <List>
                                    <ListItem button onClick={handleDrawerToggle}>
                                        <ListItemText>
                                            <Link to="home" smooth={true} duration={500}>
                                                Home
                                            </Link>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem button onClick={handleDrawerToggle}>
                                        <ListItemText>
                                            <Link to="about" smooth={true} duration={500}>
                                                About
                                            </Link>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem button onClick={handleDrawerToggle}>
                                        <ListItemText>
                                            <Link to="portfolio" smooth={true} duration={500}>
                                                Portfolio
                                            </Link>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem button onClick={handleDrawerToggle}>
                                        <ListItemText>
                                            <Link to="projects" smooth={true} duration={500}>
                                                Projects
                                            </Link>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem button onClick={handleDrawerToggle}>
                                        <ListItemText>
                                            <Link to="technology" smooth={true} duration={500}>
                                                Technology
                                            </Link>
                                        </ListItemText>
                                    </ListItem>
                                    <ListItem button onClick={handleDrawerToggle}>
                                        <ListItemText>
                                            <Button onClick={handleContactOpen} className="button-red-outline">
                                                Contact
                                            </Button>
                                        </ListItemText>
                                    </ListItem>
                                </List>
                            </Drawer>
                        </>
                    ) : (
                        // Show full nav links on larger screens
                        <div className="nav-links" style={{ marginLeft: 'auto' }}>
                            {navLinks}
                        </div>
                    )}
                </Toolbar>
            </Container>
        </AppBar>
    );
};

export default Header;