import React, { useState } from 'react';
import { Container, Grid, Typography, Button } from '@mui/material';
import './_CallToAction.scss';
import ProjectModal from "../ProjectModal/ProjectModal";

const CallToAction = () => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    return (
        <Container className="call-to-action">
            <Grid container spacing={3} alignItems="center">
                {/* Left Column: Text */}
                <Grid item xs={12} md={8} lg={9}>
                    <div className="cta-content">
                        <Typography variant="h6" className="animated-text">
                            Let’s Talk
                        </Typography>
                        <Typography variant="h2" className="animated-text">
                            about your <br />
                            <b className="back-color">NEXT project.</b>
                        </Typography>
                    </div>
                </Grid>

                {/* Right Column: Button */}
                <Grid item xs={12} md={4} lg={3} className="cta-button-container">
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={handleOpen}
                        className="cta-button button-red-outline"

                    >
                        Start Your Project
                    </Button>

                    {/* Project Modal */}
                    <ProjectModal open={open} handleClose={handleClose} />
                </Grid>
            </Grid>
        </Container>
    );
};

export default CallToAction;
