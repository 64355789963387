import React, { useState } from 'react';
import { Container, TextField, Button, MenuItem, Typography } from '@mui/material';
import './_Contact.scss';

const Contact = () => {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        phone: '',
        service: '',
        description: '',
    });

    const services = [
        { value: 'website', label: 'Website' },
        { value: 'ecommerce', label: 'E-commerce' },
        { value: 'branding', label: 'Branding' },
    ];

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();

        // Make a POST request to contact.php
        fetch('contact.php', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            body: new URLSearchParams(formData),
        })
            .then(response => {
                if (response.ok) {
                    console.log('Form submitted successfully');
                    // Optionally redirect to a thank you page
                } else {
                    console.error('Form submission failed');
                }
            })
            .catch(error => {
                console.error('Error:', error);
            });
    };

    return (
        <section className="contact" id="contact">
            <Container>
                <Typography variant="h4" className="contact-title">
                    Contact Us
                </Typography>
                <form className="contact-form" onSubmit={handleSubmit}>
                    <TextField
                        label="Name"
                        variant="outlined"
                        name="name"
                        value={formData.name}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        required
                    />
                    <TextField
                        label="Email"
                        variant="outlined"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        type="email"
                        required
                    />
                    <TextField
                        label="Phone"
                        variant="outlined"
                        name="phone"
                        value={formData.phone}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        type="tel"
                    />
                    <TextField
                        label="Service"
                        select
                        name="service"
                        value={formData.service}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        required
                    >
                        {services.map((option) => (
                            <MenuItem key={option.value} value={option.value}>
                                {option.label}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="Project Description"
                        name="description"
                        value={formData.description}
                        onChange={handleChange}
                        fullWidth
                        margin="normal"
                        variant="outlined"
                        multiline
                        rows={4}
                        required
                    />
                    <Button
                        type="submit"
                        variant="contained"
                        className="contact-submit"
                        fullWidth
                    >
                        Submit
                    </Button>
                </form>
            </Container>
        </section>
    );
};

export default Contact;
