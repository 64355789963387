import React from 'react';
import { Container, Grid, Typography, Box } from '@mui/material';
import './_FeaturesSection.scss';

const features = [
    {
        title: 'Software Development',
        description: 'We build powerful, scalable, and tailored software solutions that meet your business needs.',
        icon: 'pe-7s-tools',
    },
    {
        title: 'Web & Mobile Development',
        description: 'Our expertise in web and mobile development ensures seamless, responsive, and engaging digital experiences.',
        icon: 'pe-7s-phone',
    },
    {
        title: 'AI & Blockchain Integration',
        description: 'We leverage cutting-edge technologies like AI and blockchain to create innovative, future-ready products.',
        icon: 'pe-7s-network',
    },
];

const FeaturesSection = () => {
    return (
        <Container className="features-section">
            <Grid container justifyContent="center" className="sec-head text-center">
                <Grid item xs={12} md={10} lg={8}>
                    <Box className="header-box">

                        <Typography variant="h3" className="color-font animated">
                            We drive innovation through digital solutions
                        </Typography>
                    </Box>
                </Grid>
            </Grid>

            <Grid container spacing={3}>
                {features.map((feature, index) => (
                    <Grid item xs={12} md={4} key={index}>
                        <Box className={`item-box fadeInLeft`} style={{ animationDelay: `${0.5 + index * 0.2}s` }}>
                            <span className={`icon ${feature.icon}`}></span>
                            <Typography variant="h6">{feature.title}</Typography>
                            <Typography variant="body1">{feature.description}</Typography>
                        </Box>
                    </Grid>
                ))}
            </Grid>
        </Container>
    );
};

export default FeaturesSection;
