import React from 'react';
import {Container, Grid, Card, CardMedia, Typography} from '@mui/material';
import './_Portfolio.scss';
import img from '../../images/evolve-port-1.jpg';
import img2 from '../../images/evolve-port-2.jpg';

const Portfolio = () => {
    const projects = [
        { id: 1, name: 'Project 1', link: '#', image: img },
        { id: 2, name: 'Project 2', link: '#', image: img2 },

    ];

    return (
        <div className="portfolio-section">
            <Typography variant="h5" className="subtitle" >
                Our Work
            </Typography>
            <Container className="portfolio" id="portfolio">
                <Grid container spacing={3}>
                    {projects.map((project) => (
                        <Grid item xs={12} sm={6} key={project.id}>
                            <Card className="portfolio-item">
                                <a href={project.link}>
                                    <CardMedia
                                        component="img"
                                        alt={project.name}
                                        image={project.image}
                                    />
                                </a>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            </Container>
        </div>
    );
};

export default Portfolio;
