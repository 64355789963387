import React from 'react';
import {Box, Button, Grid, Typography} from "@mui/material";
const technologies = {
    Frontend: ['JavaScript', 'React', 'NextJS', 'React Native'],
    Backend: ['Node.js', 'NestJs', 'ExpressJS', 'Python', 'Django', 'Flask'],
    CMS_Ecommerce: ['Wordpress', 'WooCommerce', 'Shopify'],
    Databases: ['MongoDB', 'SQL', 'GraphQL'],
    DevOps_Cloud: ['AWS', 'Firebase', 'Heroku'],
    AI_ML: ['TensorFlow', 'PyTorch']
};

const Technologies = () => {


    return (
        <Box className="tech-stack">
            <Typography variant="h6" className="tech-title">
                Technology Stack
            </Typography>
            {Object.entries(technologies).map(([category, techList], catIndex) => (
                <Box key={catIndex} mt={4}>
                    {/* Display the category name */}
                    <Typography variant="h8" gutterBottom className="tech-title-alt">
                        {category.replace('_', ' & ')} {/* Format the category names */}
                    </Typography>
                    <Grid container spacing={2}>
                        {techList.map((tech, index) => (
                            <Grid item xs={12} sm={4} key={index}>
                                <Button
                                    variant="outlined"
                                    className="tech-button"
                                >
                                    {tech}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>
                </Box>
            ))}
        </Box>
    );


}
export default Technologies;