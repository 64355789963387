import React from 'react';
import { Container, Grid, Typography, Button, Box } from '@mui/material';
import './_About.scss';
import Technologies from "../Technologies/Technologies";
import Clients from "../Clients/Clients";

const technologies = [
    'JavaScript', 'React', 'Node.js', 'Python', 'Django',
    'NestJS', 'Material UI', 'Sass', 'Docker', 'AWS', 'GraphQL'
];

const About = () => {
    return (
        <Container className="about" id="about">
            <Grid container spacing={3}>
                {/* Left Column: Technology Stack */}
                <Grid item xs={12} sm={6} className="left-column">
                    <Technologies/>

                </Grid>

                {/* Right Column: About Us Description */}
                <Grid item xs={12} sm={6} className="right-column">
                    <Typography variant="h4" className="about-title">
                        About Us
                    </Typography>
                    <Typography variant="body1" paragraph>
                        At Evolve Labs, we are driven by a passion for innovation and a deep commitment to shaping the future of technology. As a full-service development studio, we specialize in building robust digital solutions, including websites, e-commerce platforms, and custom web and mobile applications.
                    </Typography>
                    <Typography variant="body1" paragraph className="highlighted-text">
                        Our mission is to empower businesses with cutting-edge technology that elevates their brand and drives success.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        From intuitive websites that captivate audiences to powerful e-commerce platforms that enhance the shopping experience, we bring ideas to life with precision and creativity.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Our expertise extends into developing scalable web and mobile applications that are tailored to meet the unique needs of your business, ensuring seamless performance across all devices.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        At Evolve Labs, we stay at the forefront of emerging trends, integrating AI and automation to streamline processes and deliver exceptional user experiences.
                    </Typography>
                    <Typography variant="body1" paragraph className="highlighted-text">
                        Whether you're a startup looking to establish a digital presence or an enterprise aiming to revolutionize your online platform, we provide the tools and expertise to make it happen.
                    </Typography>
                    <Typography variant="body1" paragraph>
                        Technology isn't just what we do—it's who we are. Our team is dedicated to excellence in every project, working closely with clients to bring their vision to life and evolve their digital presence for the future.
                    </Typography>
                    <Typography variant="body1" paragraph className="conclusion">
                        Let’s build something amazing together.
                    </Typography>
                </Grid>
            </Grid>
            <Clients/>
        </Container>
    );
};

export default About;
