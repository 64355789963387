import React from 'react';
import { Container, Typography, Box } from '@mui/material';
import './_Hero.scss';

const Hero = () => {
    return (
        <section className="hero" id="home">
            <Container>
                {/* Dark box around the "Evolve Labs" text */}
                <Box className="evol-labs-box">
                    <Typography variant="h2" className="hero-title">
                        <span className="red-text">EVOL</span>
                        <span className="light-grey-text">VE LABS</span>
                    </Typography>
                </Box>

                {/* Red outline box around the tagline */}
                <Box className="innovation-box">
                    <Typography variant="h6" className="hero-subtitle">
                        Innovative Branding, Development, and Technology
                    </Typography>
                </Box>
            </Container>
        </section>
    );
};

export default Hero;
