import React, { useState } from 'react';
import { Grid, Card, Typography, CardContent, Modal, Box, Button } from '@mui/material';
import './_Projects.scss';

const Projects = () => {
    // Project data with titles, descriptions, and tech stack
    const projects = [
        {
            id: 1,
            name: 'Evol Mind',
            description: 'Evol Mind is an advanced AI-driven platform designed to enhance decision-making and automate workflows across various industries. Leveraging cutting-edge artificial intelligence, Evol Mind offers capabilities such as natural language processing, predictive analytics, and real-time data insights. It adapts to your unique business needs, improving efficiency and helping organizations make smarter, data-driven decisions.',
            techStack: 'Backend: Python (AI/ML), Flask, PostgreSQL | Frontend: React, Material UI | Database: PostgreSQL'
        },
        {
            id: 2,
            name: 'Quick Pulse',
            description: 'Quick Pulse is an intelligent notification system designed to keep you informed and updated in real-time. Its customizable alerts allow you to receive updates across various platforms, ensuring seamless communication and project flow.',
            techStack: 'Backend: Node.js (NestJS), WebSockets | Frontend: React, Material UI | Database: MongoDB'
        },
        {
            id: 3,
            name: 'Wolf Chain',
            description: 'Wolf Chain is a blockchain-powered platform integrated with AI to offer unparalleled security, transparency, and efficiency in transactions and data management. By combining blockchain and AI, it enhances smart contracts, data integrity, and transaction processing.',
            techStack: 'Backend: Ethereum (Solidity), Python (AI/ML) | Frontend: React, Material UI'
        },
        {
            id: 4,
            name: 'InvoSync',
            description: 'InvoSync is a powerful invoicing SaaS application designed to simplify billing and payment processes for businesses of all sizes. With features such as automated invoicing and seamless integration with accounting software, InvoSync ensures you stay organized and get paid faster.',
            techStack: 'Backend: Node.js (NestJS), PostgreSQL | Frontend: React, Material UI'
        },
        {
            id: 5,
            name: 'Client Assist',
            description: 'Client Assist is an AI-powered chatbot designed to provide your clients with real-time updates on their ongoing projects, enhancing client satisfaction by fostering transparency and trust in your business processes.',
            techStack: 'Backend: Python (AI/ML), Flask | Frontend: React, Material UI'
        },
        {
            id: 6,
            name: 'EstimaX',
            description: 'EstimaX is a SaaS-based project estimator tool tailored for designers and agencies. EstimaX helps users generate professional quotes, customize pricing, and manage project estimates efficiently.',
            techStack: 'Backend: Node.js (NestJS), PostgreSQL | Frontend: React, Material UI'
        },
        {
            id: 7,
            name: 'CVPro',
            description: 'CVPro is a digital CV tool that allows users to create and share professional resumes online. CVPro offers customizable templates and seamless PDF generation for modern, accessible resumes.',
            techStack: 'Backend: Node.js (NestJS), PostgreSQL | Frontend: React, Material UI'
        },
        {
            id: 8,
            name: 'UXSmart',
            description: 'UXSmart is an AI-powered platform that analyzes user interactions on websites and applications, providing real-time insights into user behavior and suggesting UX improvements based on predictive models.',
            techStack: 'Backend: Python (AI/ML), Flask | Frontend: React, Material UI | Database: PostgreSQL'
        },
        {
            id: 9,
            name: 'CyberSecPro',
            description: 'CyberSecPro is an AI-driven cybersecurity education platform that creates personalized learning paths and interactive challenges based on the user’s experience and current cybersecurity threats.',
            techStack: 'Backend: Python (AI/ML), Flask/Django | Frontend: React, Material UI | Database: PostgreSQL'
        },
        {
            id: 10,
            name: 'CodeGuard',
            description: 'CodeGuard is an AI-driven tool that automatically obfuscates and optimizes code, offering tailored security and performance improvements for different platforms.',
            techStack: 'Backend: Python (AI/ML), Flask/Node.js | Frontend: React, Material UI | Obfuscation Libraries: JavaScript/TypeScript'
        },
        {
            id: 11,
            name: 'BugHunter',
            description: 'BugHunter is an AI-powered autonomous bug bounty platform for vulnerability assessment and bug triaging, offering faster, more efficient reporting and rewards for ethical hackers.',
            techStack: 'Backend: Python (AI/ML), Flask, Blockchain (Ethereum) | Frontend: React, Material UI'
        },
        {
            id: 12,
            name: 'ThreatSense',
            description: 'ThreatSense is a real-time threat intelligence platform that uses AI to gather and analyze data from various sources, providing automated threat assessments and predictions.',
            techStack: 'Backend: Python (AI/ML), Flask, Web Scrapers | Frontend: React, Material UI | Database: MongoDB'
        },
        {
            id: 13,
            name: 'IDSecure',
            description: 'IDSecure is a blockchain-based digital identity verification platform leveraging AI for biometric and behavioral verification, ensuring secure and decentralized identity management.',
            techStack: 'Backend: Ethereum Blockchain, Python (AI/ML) | Frontend: React, Material UI | Database: MongoDB'
        },
        {
            id: 14,
            name: 'DeploySmart',
            description: 'DeploySmart is an AI-powered DevOps platform for optimizing continuous deployment pipelines, offering real-time adjustments and predictive scaling for improved performance.',
            techStack: 'Backend: Python (AI/ML), Docker, Kubernetes | Frontend: React, Material UI | Database: MongoDB'
        },
        {
            id: 15,
            name: 'DATNet',
            description: 'DATNet is a decentralized AI training network where users can contribute data and computing power in exchange for tokens, enabling collaborative AI model training.',
            techStack: 'Backend: Ethereum Blockchain, Python (AI/ML), Solidity | Frontend: React, Material UI | Database: IPFS or distributed storage'
        },
        {
            id: 16,
            name: 'MicroServe',
            description: 'MicroServe is an AI-powered platform for managing and optimizing microservices architecture, offering predictive resource allocation and performance improvements.',
            techStack: 'Backend: Node.js/NestJS, Python (AI for optimization), Docker, Kubernetes | Frontend: React, Material UI'
        }
    ];

    // Modal state
    const [open, setOpen] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);

    // Handle modal open
    const handleOpen = (project) => {
        setSelectedProject(project);
        setOpen(true);
    };

    // Handle modal close
    const handleClose = () => {
        setOpen(false);
        setSelectedProject(null);
    };

    return (
        <div className="projects-section" id="projects">
            <Typography variant="h5" className="subtitle">
                Projects Currently in Development
            </Typography>
            <Grid container spacing={3}>
                {projects.map((project) => (
                    <Grid item xs={12} sm={6} md={3} key={project.id}>
                        <Card className="project-item" onClick={() => handleOpen(project)}>
                            <CardContent>
                                <Typography variant="h6">{project.name}</Typography>
                                <div className="project-description-box">
                                    <Typography variant="body2" color="textSecondary" gutterBottom>
                                        {project.description.substring(0, 100)}...
                                    </Typography>
                                </div>
                                <Button className="learn-more" color="primary">
                                    Learn more →
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                ))}
            </Grid>

            {/* Modal for project details */}
            <Modal open={open} onClose={handleClose}>
                <Box className="modal-box">
                    {selectedProject && (
                        <>
                            <Typography variant="h5">{selectedProject.name}</Typography>
                            <Typography variant="body1" mt={2}>{selectedProject.description}</Typography>
                            <Typography variant="body2" mt={2}><strong>Tech Stack:</strong> {selectedProject.techStack}</Typography>
                            <Button onClick={handleClose} variant="contained" color="primary" mt={3}>
                                Close
                            </Button>
                        </>
                    )}
                </Box>
            </Modal>
        </div>
    );
};

export default Projects;
