import React from 'react';
import { Container, Grid, Typography, Box, IconButton } from '@mui/material';
import { Facebook, Instagram } from '@mui/icons-material';
import './_Footer.scss';

const Footer = () => {
    return (
        <footer className="footer">
            <Container>
                <Grid container alignItems="center" justifyContent="space-between">
                    {/* Left side: Company name and email */}
                    <Grid item>
                        <Typography variant="body1" className="company-info">
                            Evolve Labs - contactus@evolvedevlabs.com
                        </Typography>
                    </Grid>

                    {/* Right side: Social media icons */}
                    <Grid item>
                        <Box className="social-icons">
                            <IconButton href="https://www.facebook.com" target="_blank" className="icon-button">
                                <Facebook />
                            </IconButton>
                            <IconButton href="https://www.instagram.com" target="_blank" className="icon-button">
                                <Instagram />
                            </IconButton>
                        </Box>
                    </Grid>
                </Grid>
            </Container>
        </footer>
    );
};

export default Footer;
